import PropTypes from 'prop-types';
import React from 'react';

const LinkExternal = ({ href, children, ...props }) => (
  <a href={href} {...props}>
    {children}
  </a>
);

LinkExternal.propTypes = {
  href: PropTypes.string,
};

LinkExternal.defaultProps = {
  href: ``,
};

export default LinkExternal;
