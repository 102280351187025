import PropTypes from 'prop-types';
import React from 'react';

const Figure = ({ image, caption, width = 500, children }) => (
  <figure className={`figure`} style={{ width: `${width}px` }}>
    <img
      src={image}
      width={width}
      style={{ maxWidth: '100%' }}
      alt="{caption ? caption : children}"
    />
    <figcaption className="figure-caption">
      {caption ? caption : children}
    </figcaption>
  </figure>
);

Figure.propTypes = {
  image: PropTypes.string,
  caption: PropTypes.string,
  width: PropTypes.number,
};

Figure.defaultProps = {
  image: null,
  caption: null,
  width: 500,
};

export default Figure;
