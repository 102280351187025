import React from 'react';
import classNames from 'classnames';

const Row = ({ children, className, ...props }) => (
  <div className={classNames(`row`, className)} {...props}>
    {children}
  </div>
);

export default Row;
