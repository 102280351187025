import PropTypes from 'prop-types';
import React from 'react';

import LinkExternal from './link-external';

const LinkEmail = ({ email, ...props }) => (
  <LinkExternal href={`mailto:${email}`} {...props}>
    {email}
  </LinkExternal>
);

LinkExternal.propTypes = {
  email: PropTypes.string,
};

LinkExternal.defaultProps = {
  email: ``,
};

export default LinkEmail;
