import Column from './column';
import Figure from './figure';
import Link from './link';
import LinkButton from './link-button';
import LinkEmail from './link-email';
import LinkExternal from './link-external';
import Row from './row';
import Text from './text';

const shortcodes = {
  Column,
  Figure,
  Link,
  LinkButton,
  LinkEmail,
  LinkExternal,
  Row,
  Text,
};

export default shortcodes;
