import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import LinkExternal from './link-external';

const LinkButton = ({
  href,
  children,
  variant = 'primary',
  className,
  ...props
}) => (
  <LinkExternal
    type="button"
    href={href}
    className={classNames(`btn btn-${variant}`, className)}
    {...props}
  >
    {children}
  </LinkExternal>
);

LinkButton.propTypes = {
  href: PropTypes.string,
  variant: PropTypes.string,
};

LinkButton.defaultProps = {
  href: ``,
  variant: 'primary',
};

export default LinkButton;
