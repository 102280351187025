import PropTypes from 'prop-types';
import React from 'react';

import { Link } from './link';

const MenuItem = ({ title, to }) => (
  <li>
    <Link to={to} className="menu-item" activeClassName="current-menu-item">
      {title}
    </Link>
  </li>
);

MenuItem.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
};

MenuItem.defaultProps = {
  title: ``,
  to: ``,
};

export default MenuItem;
