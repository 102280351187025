import PropTypes from 'prop-types';
import React from 'react';

const PageHeader = ({ title }) => (
  <div className="page-header">
    <h1>{title}</h1>
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.string,
};

PageHeader.defaultProps = {
  title: ``,
};

export default PageHeader;
