/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { MDXProvider } from '@mdx-js/react';
import React from 'react';
import PropTypes from 'prop-types';

import { useSiteMetadata } from '../hooks/use-site-metadata';
import '../styles/main.scss';

import shortcodes from './shortcodes';
import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <MDXProvider components={shortcodes}>
      <Header siteTitle={title} siteDescription={description} />
      <div className="wrap container" role="document">
        <div className="content row">
          <main className="main">{children}</main>
        </div>
      </div>
      <Footer siteTitle={title} />
    </MDXProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
