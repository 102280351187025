import React from 'react';
import { FaBars } from 'react-icons/fa';
import { Nav, Navbar } from 'react-bootstrap';

import MenuItem from './menu-item';

const Menu = ({ menuItems = [] }) => (
  <Navbar collapseOnSelect>
    <Navbar.Header>
      <div className="text-center">
        <Navbar.Toggle aria-label="menu toggle">
          <FaBars size={24} />
        </Navbar.Toggle>
      </div>
    </Navbar.Header>
    <Navbar.Collapse>
      <Nav>
        {menuItems.map((item) => (
          <MenuItem key={item.to} title={item.title} to={item.to} />
        ))}
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default Menu;
