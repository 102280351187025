import PropTypes from 'prop-types';
import React from 'react';

import Hero from './hero';
import Menu from './menu';

const menuItems = [
  { title: 'Home', to: '/' },
  { title: 'Lt. Leslie Miller', to: '/lt-leslie-miller/' },
  { title: 'Missions Accomplished', to: '/mission-accomplished/' },
  { title: 'Centennial Park', to: '/centennial-park/' },
  { title: 'VOLC Members', to: '/who-are-the-volc/' },
  { title: 'Contact Us', to: '/contact-us/' },
];

const Header = ({ siteTitle, siteDescription }) => (
  <>
    <div className="banner hero jumbotron">
      <div className="container">
        <h1 className="text-center">{siteTitle}</h1>
        <Hero siteTitle={siteTitle} siteDescription={siteDescription} />
      </div>
    </div>

    <header id="top">
      <Menu menuItems={menuItems}></Menu>
    </header>
  </>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
};

export default Header;
