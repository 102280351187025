import PropTypes from 'prop-types';
import React from 'react';

// import BuyButton from './buy-button';
import Column from './column';
import Link from './link';
import Row from './row';
import Text from './text';

import siteLogo from '../images/vimy-oaks-logo.png';

const Hero = ({ siteTitle, siteDescription }) => (
  <Row>
    <Column colSpan={3} className="hero-logo">
      <Text className="text-center">
        <Link to="/" className="navbar-link contact-email">
          <img className="site-logo" src={siteLogo} alt={siteTitle} />
        </Link>
      </Text>
    </Column>
    <Column colSpan={9} className="hero-info">
      <Text className="site-tagline">{siteDescription}</Text>
      {/* <Text className="call-to-action">
        <BuyButton />
      </Text> */}
    </Column>
  </Row>
);

Hero.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
};

Hero.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
};

export default Hero;
