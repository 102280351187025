import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Column = ({
  children,
  colSpan = 6,
  justify = false,
  className,
  ...props
}) => (
  <div
    className={classNames(
      `col-md-${colSpan}`,
      { 'text-justify': justify },
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

Column.propTypes = {
  colSpan: PropTypes.number,
  justify: PropTypes.bool,
};

Column.defaultProps = {
  colSpan: 6,
  justify: false,
};

export default Column;
