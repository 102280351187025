import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from './layout';
import PageHeader from './page-header';
import SEO from './seo';

const Page = ({ children, ...props }) => {
  useIntl();
  const { title } = props.pageContext.frontmatter;
  return (
    <Layout>
      <SEO title={title} />
      <PageHeader title={title} />
      {children}
    </Layout>
  );
};

export default Page;
