import PropTypes from 'prop-types';
import React from 'react';
import { FaEnvelope } from 'react-icons/fa';

import Link from './link';
import Column from './column';
import Text from './text';

const Footer = ({ siteTitle, year = new Date().getFullYear() }) => (
  <footer className="footer content-info" role="contentinfo">
    <div className="container">
      <nav className="row" role="navigation">
        <Column colSpan={4}>
          <Text className="copyright">
            &copy; {year} {siteTitle}
          </Text>
        </Column>
        <Column colSpan={8} className="footer-right">
          <ul className="list-inline">
            <li>
              <FaEnvelope className="icon" /> &nbsp;
              <Link to="/contact-us/" className="navbar-link">
                Contact Us
              </Link>
            </li>
          </ul>
        </Column>
      </nav>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
